import { graphql } from 'gatsby'
import Layout from "../components/layout"
import CardContainer from '../components/Containers/CardContainer/CardContainer'
import Card from "../components/Molecules/Card/Card"
import config from '../utils/siteConfig'
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import React, { Component } from 'react'
import FilterContainer from '../components/Containers/FilterContainer/FilterContainer'
import '../styles/bootstrap.css'
import '../styles/fonts.scss'
import '../styles/components/buttons.css'
import '../styles/global.scss'
import '../styles/components/navbar.css'
import '../styles/components/cards.css'
import AssetLabel from '../components/Atoms/resources/AssetLabel/AssetLabel'
import CategoryLabel from '../components/Atoms/resources/CategoryLabel/CategoryLabel'
import TagLabel from '../components/Atoms/resources/TagLabel/TagLabel'
import ShowMoreBtn from '../components/Atoms/resources/ShowMoreBtn/ShowMoreBtn'
import AllResourceLoadedBtn from '../components/Atoms/resources/AllResourceLoadedBtn/AllResourceLoadedBtn'





class ResourceCenter extends Component {
  constructor(props) {
    super(props)
    debugger;
    // arrays
    this.optionsAssetTypes = config.assetTypes
    this.optionsTagTypes = props.data.allContentfulTag.distinct
    if (!props.data.allContentfulTag.distinct.some(value => (value.title === 'All'))) {
      this.optionsTagTypes.unshift('All')
    }

    this.optionsCategoryTypes = props.data.allContentfulCategory.distinct
    if (!props.data.allContentfulCategory.distinct.some(value => (value.title === 'All'))) {
      this.optionsCategoryTypes.unshift('All')
    }

    debugger;
    // combining all the content
    this.blogs = props.data.allContentfulBlog.edges
    this.news = props.data.allContentfulNews.edges

    this.resources = this.blogs.concat(this.news)


    this.resourceCount = config.resourcesInitialShowCount;
    this.LoadMore = true;
    if (this.resourceCount >= this.resources.length) {
      this.LoadMore = false;
    }

    this.state = {
      selected: '',
      isResult: true,
      loadMore: this.LoadMore,
      totalResources: this.resources.length,
      tag: this.optionsTagTypes[0],
      categoryType: this.optionsCategoryTypes[0],
      assetType: this.optionsAssetTypes[0]
    }

    this._onSelectTag = this._onSelectTag.bind(this)
    this._onSelectCategory = this._onSelectCategory.bind(this)
    this._onSelectAssetType = this._onSelectAssetType.bind(this)
    this._onLoadMoreResources = this._onLoadMoreResources.bind(this)

    this.defaultOptionAssetTypes = this.optionsAssetTypes[0];
    this.defaultOptionTagTypes = this.optionsTagTypes[0];
    this.defaultOptionCategoryTypes = this.optionsCategoryTypes[0];

    this.allData = []

    // AOS.init();
  }



  _onLoadMoreResources() {
    debugger;
    this.setState({ loadMore: false })
    this.resourceCount = this.resources.length
    var filterAssetTypes = this.state.assetType
    var filterTagTypes = this.state.tag
    var filterCategoryTypes = this.state.categoryType

    this.checkFilterResults(filterAssetTypes, filterTagTypes, filterCategoryTypes)
    this.checkFilterResultsCount(this.allData.length)
  }

  _onSelectTag(option) {
    console.log('You selected ', option.label)
    this.setState({ tag: option.label })

    var filterAssetTypes = this.state.assetType
    var filterTagTypes = option.label
    var filterCategoryTypes = this.state.categoryType

    this.checkFilterResults(filterAssetTypes, filterTagTypes, filterCategoryTypes)
    this.checkFilterResultsCount(this.allData.length)
  }

  _onSelectCategory(option) {
    console.log('You selected ', option.label)
    this.setState({ categoryType: option.label })

    var filterAssetTypes = this.state.assetType
    var filterTagTypes = this.state.tag
    var filterCategoryTypes = option.label

    this.checkFilterResults(filterAssetTypes, filterTagTypes, filterCategoryTypes)
    this.checkFilterResultsCount(this.allData.length)
  }


  _onSelectAssetType(option) {
    console.log('You selected ', option.label)
    this.setState({ assetType: option.label })

    var filterAssetTypes = option.label
    var filterTagTypes = this.state.tag
    var filterCategoryTypes = this.state.categoryType

    this.checkFilterResults(filterAssetTypes, filterTagTypes, filterCategoryTypes)
    this.checkFilterResultsCount(this.allData.length)
  }


  componentDidMount(prevProps, prevState) {
    var filterAssetTypes = this.state.assetType
    var filterTagTypes = this.state.tag
    var filterCategoryTypes = this.state.categoryType

    this.checkFilterResults(filterAssetTypes, filterTagTypes, filterCategoryTypes)
    this.checkFilterResultsCount()

    this.setState({ assetType: this.defaultOptionAssetTypes })
    // this.aos = AOS;
    // AOS.init({});
  }



  // componentDidUpdate() {
  //   AOS.refresh();
  // }


  checkFilterResults(filterAssetTypes, filterTagTypes, filterCategoryTypes) {
    debugger;

    if (filterAssetTypes === "All" && filterTagTypes === "All" && filterCategoryTypes === "All") {
      this.allData = this.resources;
    } else {
      this.allData = this.resources.filter(function (_resource) {
        return (
          (filterAssetTypes != "All" ? (_resource.node.contentType === filterAssetTypes) : true) &&

          (filterCategoryTypes != "All" ? _resource.node.categories.some(value => (value.title === filterCategoryTypes || value.slug === filterCategoryTypes)) : true) &&

          (filterTagTypes != "All" ? _resource.node.tags.some(value => (value.title === filterTagTypes || value.slug === filterTagTypes)) : true)
        );
      });
    }

    this.allData = this.allData.slice(0, this.resourceCount)

  }

  checkFilterResultsCount() {
    if (this.allData.length > 0) {
      this.setState({ isResult: true })
    } else {

      this.setState({ isResult: false })
    }
  }


  render() {

    return (
      <Layout
        IsHeaderImageDark={false}>
        

        <section>
          <div class="page-content">
            <FilterContainer>
              <div style={{ margin: 20 }}>
                <label for="AssetTypes"><AssetLabel /></label>
                <div style={{ width: 200 }}><Dropdown onChange={this._onSelectAssetType} options={this.optionsAssetTypes} value={this.defaultOptionAssetTypes} placeholder="Select Asset Type" /></div>
              </div>
              <div style={{ margin: 20 }}>
                <label for="Category"><CategoryLabel /></label>
                <div style={{ width: 200 }}><Dropdown onChange={this._onSelectCategory} options={this.optionsCategoryTypes} value={this.defaultOptionCategoryTypes} placeholder="Select Category Type" /></div>
              </div>

              <div style={{ margin: 20 }}>
                <label for="Tag"><TagLabel /></label>
                <div style={{ width: 200 }}><Dropdown onChange={this._onSelectTag} options={this.optionsTagTypes} value={this.defaultOptionTagTypes} placeholder="Select Tag Type" /></div>
              </div>

            </FilterContainer>

            <CardContainer>
              {this.allData.map((resource, index) => (
                <Card key={resource.node.id} {...resource.node} featured />
              ))}
            </CardContainer>

            <div style={{ textAlign: 'center', margin: '20px', marginBottom: '50px' }}>
              <button type="button" disabled={!this.state.loadMore} onClick={() => this._onLoadMoreResources()} className="btn btn-light">
                {/* {this.state.loadMore?'Show more' : 'All Resources Loaded'} */}
                {this.state.loadMore ?
                  <ShowMoreBtn></ShowMoreBtn>
                  :
                  <AllResourceLoadedBtn></AllResourceLoadedBtn>
                }
              </button>
            </div>

          </div>
        </section>

      </Layout>
    )
  }
}

export default ResourceCenter


export const query = graphql`
  query($locale: String!) {
    allContentfulTag(
      filter: {
          node_locale: {eq: $locale}
      }, 
      sort: {fields: title, order: ASC}
    ) {
      distinct(field: title)
      nodes {
        slug
        title
        id
      }
    }
    allContentfulCategory(
      filter: {
          node_locale: {eq: $locale}
      }, 
      sort: {fields: title, order: ASC}
    ) {
      distinct(field: title)
      nodes {
        slug
        title
        id
      }
    }
    allContentfulNews(
      filter: {
          node_locale: {eq: $locale}
      }, 
      sort: {fields: [publishDate], order: DESC })  
    {
      edges {
        node {
              id
              contentType
              metaData {
                title
                slug
              }
              headerImage {
                file {
                  url
                  fileName
                }
              }
              publishDate(formatString: "MMMM DD, YYYY")
              categories {
                id
                slug
                title
              }
              tags {
                id
                slug
                title
              }
              headline {
                headline
              }
              previewImage {
                file {
                  url
                  fileName
                }
              }
              publisher
              sourceLink
        }
      }
    }
    allContentfulBlog(
        filter: {
            node_locale: {eq: $locale}
        }, 
        sort: {fields: [publishDate], order: DESC }
    ) {
        edges {
          node {
            id
            contentType
            metaData {
              title
              slug
            }
            headerImage {
              file {
                url
                fileName
              }
            }
            publishDate(formatString: "MMMM DD, YYYY")
            categories {
              id
              slug
              title
            }
            tags {
              id
              slug
              title
            }
            headline {
              headline
            }
            previewImage {
              file {
                url
                fileName
              }
            }
            publisher
          }
        }
    }
  }
`
